<template>
  <div class="class-list">
    <GlobalInfoBar
      title="二维码发货"
      content="说明：管理向门店商家的二维码发货"
    />
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <QrcodeDeliveryFrom @setSuccess="setSuccess" />
      </div>

      <QrcodeDeliveryTable ref="QrcodeDeliveryTable" />
    </GlobalChunk>
  </div>
</template>

<script>
import QrcodeDeliveryFrom from "./Form";
import QrcodeDeliveryTable from "./Table";
export default {
  name: "class-list",
  components: { QrcodeDeliveryFrom, QrcodeDeliveryTable},
  data() {
    return {};
  },
  methods: {
    setSuccess(){
      this.$refs.QrcodeDeliveryTable.handleCurrentChange(1)
    }
  },
};
</script>

<style>
</style>