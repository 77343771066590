<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div slot="AddSlot">
        <el-button
          size="small"
          type="warning"
          icon="el-icon-plus"
          @click="addDeliveryRecord"
          >添加发货记录</el-button
        >
        <el-button size="small" type="primary" @click="importRecord"
          >批量导入发货记录</el-button
        >
        <el-button
          icon="el-icon-download"
          slot="AddSlot"
          type="success"
          :loading="exportLoading"
          @click="exportExcel"
          >导出Excel</el-button
        >
      </div>
    </GlobalForm>
    <el-dialog
      title="添加发货记录"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      class="add-record-dialog"
      width="600px"
      @closed="handleDialogClosed"
    >
      <div class="record-title">
        <span
          >输入批次号后仅查询该批次号中的商家。连接扫码枪后，扫码结果将自动填充到输入框</span
        >
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="批次号:" prop="batchCode">
          <el-select
            v-model="ruleForm.batchCode"
            @change="batchCodeChange"
            placeholder="请选择批次号查询"
          >
            <el-option
              v-for="item in batchCodeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="寄送商家:" prop="companyId">
          <el-select
            v-model="ruleForm.companyId"
            @change="businessChange"
            placeholder="请选择商家名称查询"
          >
            <el-option
              v-for="item in businessList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮寄地址:" prop="shippingAddress">
          <el-input
            v-model="ruleForm.shippingAddress"
            placeholder="输入商家详细收货地址（50字内）"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="contactPhone">
          <el-input
            v-model="ruleForm.contactPhone"
            placeholder="输入商家联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="快递单号:" prop="expressNo">
          <el-input
            v-model="ruleForm.expressNo"
            placeholder="请输入或扫码单号（25字内）"
          ></el-input>
        </el-form-item>
        <el-form-item label="发货份数:" prop="volume">
          <el-input
            v-model="ruleForm.volume"
            placeholder="请输入本次发货的二维码份数（大于0小于10000的正整数）"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="recordSuccess('ruleForm')"
          >确定</el-button
        >
        <el-button @click.native="handleDialogClosed">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="批量导入发货记录"
      :visible.sync="importVisible"
      :close-on-click-modal="false"
      class="import-record-dialog"
      width="500px"
      @closed="handleDialogClosed"
    >
      <div class="step">
        <div class="step-title">
          <span>Step1</span>
        </div>
        <div class="step-content">
          <span style="margin-right: 12px">表格下载</span>
          <el-link type="primary" @click="download">点击下载模板表格</el-link>
        </div>
        <div class="step-title">
          <span>Step2</span>
        </div>
        <div class="tip">
          <span class="tip-title">上传表格</span>
          <span class="tip-content"
            >请上传.xls或.xlsx格式的excel文件，大小10M内</span
          >
        </div>
        <div class="step2-content">
          <el-upload
            ref="upload"
            :limit="1"
            :headers="headers"
            :auto-upload="false"
            :http-request="uploadFile"
            :before-upload="onUpload"
            accept=".xls,.xlsx"
            action="customize"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="importSuccess">确定</el-button>
        <el-button @click.native="handleDialogClosed">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import { encryptByDES } from "@/utils/3DES";
import { MessageBox } from "element-ui";
import moment from 'moment'

export default {
  name: "Form",
  data() {
    return {
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      initData: {
        entryTime: [moment().day(moment().day()).format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59')]
      },
      importVisible: false,
      dialogVisible: false,
      ExcelSeachParams: {},
      batchCodeList: [],
      businessList: [],
      formItemList: [
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "生成时间",
          placeholder: "请选择生成时间",
          valueFormat: "timestamp",
          clearable: true,
        },
        {
          key: "batchCode",
          type: "input",
          labelName: "批次号",
          placeholder: "输入批次号查询",
        },
        {
          key: "companyName",
          type: "input",
          labelName: "商家名称",
          placeholder: "输入商家名称查询",
        },
        { solt: "AddSlot" },
      ],
      ruleForm: {
        batchCode: "",
        companyId: "",
        contactPhone: "",
        expressNo: "",
        shippingAddress: "",
        volume: "",
      },
      rules: {
        batchCode: [
          { required: true, message: "请选择批次号查询", trigger: "blur" },
        ],
        companyId: [
          { required: true, message: "请选择商家名称查询", trigger: "change" },
        ],
        shippingAddress: [
          {
            required: true,
            message: "输入商家详细收货地址（50字内）",
            trigger: "change",
          },
          {
            min: 1,
            max: 50,
            message: "收货地址长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        contactPhone: [
          { required: true, message: "输入商家联系电话", trigger: "change" },
        ],
        expressNo: [
          {
            required: true,
            message: "请输入或扫码单号（25字内）",
            trigger: "change",
          },
          {
            min: 1,
            max: 25,
            message: "快递单号长度在 1-25 个字符",
            trigger: "blur",
          },
        ],
        volume: [
          {
            required: true,
            message: "请输入本次发货的二维码份数（0-10000的正整数）",
            trigger: "change",
          },
        ],
      },
      exportLoading: false
    };
  },
  created() {
    this.handleConfirm(this.initData);
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {};
      if (data) {
        SeachParams = {
          batchCode: data.batchCode,
          companyName: data.companyName,
          startTime: data.entryTime? moment(data.entryTime[0]).format("x"):"",
          endTime: data.entryTime? moment(data.entryTime[1]).format("x"):"",
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
            if (typeof cd === 'function') {
              cd()
            }
          },
        };
      }
      this.ExcelSeachParams = SeachParams
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 获取所有批次号
    batchCodeSelectList() {
      _api.batchCodeSelectList().then((res) => {
        if (res.code === 1) {
          this.batchCodeList = res.data;
        }
      });
    },
    // 查询该批次号下的商家
    companyListByBarCode(batchCode) {
      _api.companyListByBarCode({ batchCode }).then((res) => {
        if (res.code === 1) {
          this.businessList = res.data;
        }
      });
    },
    addDeliveryRecord() {
      this.batchCodeSelectList();
      this.dialogVisible = true;
    },
    batchCodeChange(e) {
      this.companyListByBarCode(e);
    },
    businessChange(e) {
      this.businessList.forEach((item) => {
        if (e == item.companyId) {
          this.ruleForm.shippingAddress = item.companyAddress;
          this.ruleForm.contactPhone = item.contactPhone;
        }
      });
    },
    recordSuccess(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          _api.barCodeSendRecordSave(this.ruleForm).then((res) => {
            if (res.code === 1) {
              this.$message.success("添加成功");
              this.$emit('setSuccess')
              this.dialogVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量导入发货记录弹窗显示
    importRecord() {
      this.importVisible = true;
    },
    // 批量导入发货记录弹窗隐藏
    handleDialogClosed() {
      this.importVisible = false;
      this.dialogVisible = false;
    },
    importSuccess() {
      this.$refs.upload.submit();
      this.importVisible = false;
      this.$refs.upload.clearFiles();
    },
    // 上传文件
    uploadFile(params) {
      console.log("uploadFile", params);
      const _file = params.file;
      // 通过 FormData 对象上传文件
      var formData = new FormData();
      formData.append("file", _file);
      // 发起请求
      _api.importSendRecord(formData).then((res) => {
        console.log("_RequestUploads_", res);
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.$emit('setSuccess')
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
          });
        }
      });
    },
    download() {
      const link = document.createElement("a");
      link.href = "/static/发货记录导入模板.xlsx";
      link.setAttribute("download", "发货记录导入模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    onUpload(e) {
      let TOKEN = encryptByDES(
        encryptByDES(
          new Date().getTime().toString(),
          "63e42c2444e94c1ebca21d30d2aa39a5"
        ),
        localStorage.getItem("token") || ""
      );
      this.headers.TOKEN = TOKEN;
    },
    exportExcel(e) {
      this.exportLoading = true
      _api.exportBarCodeExcel(this.ExcelSeachParams).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = "二维码发货记录.xlsx"
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      }).finally(() => {
            this.exportLoading = false;
          });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .add-record-dialog {
    .record-title {
      margin-bottom: 20px;
      color: #ff687b;
    }
  }
  .import-record-dialog {
    .step {
      .step-title {
        color: #0981ff;
        margin-bottom: 10px;
        padding-left: 6px;
        border-left: 3px solid;
        border-radius: 1.5px;
      }
      .step-content,
      .step2-content {
        margin-bottom: 26px;
      }
      .step2-content > div {
        display: flex;
      }
      .step2-content {
        .upload-demo {
          display: flex;
          justify-content: start;
          .el-upload-list {
            width: 200px;
          }
        }
      }
      .tip {
        margin-bottom: 12px;
        .tip-title {
          color: #666666;
          margin-right: 12px;
        }
        .tip-content {
          color: #ff687b;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
