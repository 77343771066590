<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column
        label="本批次历史分配份数"
        slot="allVolume"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button
            v-if="row.allVolume"
            type="text"
            class="text-btn"
            @click="handleShowTimes(row)"
            >{{ row.allVolume }}</el-button
          >
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column
        label="押金收取"
        slot="depositPay"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button
            type="text"
            class="text-btn"
            @click="handleShowDeposit(row,'01','see')"
            >{{ row.depositPay }}</el-button>
          <div>
            <el-button
              type="text"
              class="text-btn"
              @click="handleShowDeposit(row,'01','add')"
              >添加</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="押金退还"
        slot="depositRefund"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button
            type="text"
            class="text-btn"
            @click="handleShowDeposit(row,'02','see')"
            >{{ row.depositRefund }}</el-button>
          <div>
            <el-button
              type="text"
              class="text-btn"
              @click="handleShowDeposit(row,'02','add')"
            >添加</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="备注说明" slot="remark" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.remark"
            class="item"
            effect="dark"
            :content="row.remark"
            placement="top"
          >
            <div class="Remarks">
            {{ row.remark }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="handleBtn(row)">{{
            row.remark ? "修改备注" : "添加备注"
          }}</el-button>
        </template>
      </el-table-column>
    </GlobalTable>

    <!--弹框-->
    <el-dialog
      title="查看历史分配"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="600px"
      @closed="handleDialogClosed"
    >
      <div>
        <span
          >合计:
          <span class="distribute-color">{{ distributeData.allVolume }}</span
          >份<span class="distribute-color" style="margin-left: 20px">{{
            distributeData.allCode
          }}</span
          >张</span
        >
      </div>
      <GlobalTable
        ref="GlobalTable"
        class="number-table"
        :maxHeight="500"
        v-loading="numberLoading"
        :columns="numberColumns"
        :data="distributeList"
        :isPagination="false"
      >
      </GlobalTable>
    </el-dialog>
    <el-dialog
      class="remark"
      :title="dialogTitle"
      :visible.sync="remarkVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="handleDialogClosed"
    >
      <el-input
        type="textarea"
        v-model="remark"
        maxlength="100"
        show-word-limit
        :rows="2"
        placeholder="请输入备注说明（限100字内）"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess"
          >确定{{ remark ? "修改" : "添加" }}</el-button
        >
        <el-button @click.native="handleDialogClosed">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="上传凭证"
      :visible.sync="voucherVisible"
      :close-on-click-modal="false"
      width="600px"
      @closed="voucherClosed"
    >
      <div class="mb-10" style="color:#FF8080;font-size:14px">请上传本次的{{opt=='01'?'收款':'退还'}}凭证（限3张内，大小不超过5M，格式为JPG或PNG）</div>
      <div class="apply-letter" style="margin-buttom:10px;">
				<ElImgUploadGroup
					:length="3"
          :fileSize="5"
          :isBigImg="true"
					:exist-img-list="voucherLetterUrl"
					@handleChange="handleUploadGroupChange($event)"
				/>
      </div>
      <div class="mb-10" style="color: #0981FF;font-size:16px">备注（非必填）</div>
      <el-input
      style="height: "
        type="textarea"
        v-model="voucherRemark"
        maxlength="50"
        show-word-limit
        placeholder="请输入备注说明（限50字内）"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="voucherClosed">取消</el-button>
        <el-button type="primary" @click.native="voucherSuccess">确定</el-button>
      </span>
    </el-dialog>
    <DialogTbale
      :loading="voucherLoading"
      :show="voucherSeeVisible"
      :title="voucherSeeTitle"
      :columns="voucherColumns"
      :data="voucherData"
      :currentPage="voucherPage.pageNum"
      :total="voucherPage.total"
      :pageSize="voucherPage.pageSize"
      :width="700"
      :isIndex="true"
      @onClose="voucherSeeClose"
      @handleCurrentChange="voucherChangeDialog"
    >
      <el-table-column label="押金凭证" slot="depositProofUrl" align="center">
        <template slot-scope="{ row }">
          <img style="width: 40px; height: 40px;" @click="seeVoucher(row)" :src="row.depositProofUrl[0]" alt="">
        </template>
      </el-table-column>
      <el-table-column label="备注" slot="remark" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.remark"
            class="item"
            effect="dark"
            :content="row.remark"
            placement="top"
          >
            <div class="Remarks">
            {{ row.remark }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <el-image-viewer
      zIndex="99999"
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
  </div>
</template>
<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
import ElImgUploadGroup from '../../../components/global/components/elements/upload-group-element.vue'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  name: "Table",
  components: {ElImageViewer, ElImgUploadGroup },
  mixins: [MinXinRequest],
  data() {
    return {
      loading: false,
      numberLoading: false,
      seachDataList: [],
      distributeList: [],
      distributeData: {},
      ResidentEnable: {
        id: "",
        isEnable: "",
      },
      rowId: "",
      remark: "",
      opt: "01",
      btnLoading: false,
      pageNum: 1,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      remarkVisible: false,
      dialogTitle: "", // 标题
      dialogTip: "", // 弹窗提示内容
      tableColumns: [
        { label: "二维码批次号", prop: "batchCode" },
        { label: "寄送商家", prop: "companyName" },
        { label: "省市区", prop: "fullAddress" },
        { label: "邮寄地址", prop: "shippingAddress" },
        { label: "联系电话", prop: "contactPhone" },
        { label: "发货时间", prop: "sendTime" },
        { label: "快递单号", prop: "expressNo" },
        { slotName: "allVolume" },
        { label: "本次发货份数", prop: "volume" },
        { slotName: "depositPay" },
        { slotName: "depositRefund" },
        { slotName: "remark" },
        { slotName: "operation" },
      ],
      numberColumns: [
        { label: "分配时间", prop: "distributeTime" },
        { label: "分配份数", prop: "volume" },
        { label: "二维码数量（张）", prop: "allCount" },
      ],
      voucherVisible: false,
      voucherSeeVisible: false,
      voucherLoading: false,
      voucherRemark: "",
      voucherRowId: "",
      voucherSeeTitle: "",
      voucherLetterUrl: [],
      voucherData: [],
      voucherPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      voucherColumns: [
        { label: "创建时间", prop: "createTime" },
        { label: "操作人", prop: "adminName" },
        { slotName: "depositProofUrl" },
        { slotName: "remark" },
      ],
      showViewer: false,
      imgList: [],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    seachTotal() {
      return this.$store.state.tagsView.seachTotal;
    },
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.pageNum = 1;
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      this.loading = true
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      }
      const baseRequest = {
        batchCode: this.SeachParams.batchCode,
        companyName: this.SeachParams.companyName,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.$emit("handleCurrentChange", val);
      _api.barCodeSendRecordList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
          this.$store.commit("tagsView/POST_TOTAL", res.data.total);
          this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
          this.loading = false
        }
        
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 本批次本商户分配记录
    getDistribute(params) {
      this.numberLoading = true
      _api.getDistribute(params).then((res) => {
        if (res.code === 1) {
          this.distributeList = res.data.distributes;
          this.distributeData.allCode = res.data.allCode;
          this.distributeData.allVolume = res.data.allVolume;
          this.numberLoading = false
        }
      });
    },
    handleShowTimes(row) {
      this.dialogVisible = true;
      const params = {
        batchCode: row.batchCode,
        companyId: row.companyId,
      };
      this.getDistribute(params);
      console.log("查看本批次历史分配份数");
    },
    // 表格添加/修改备注操作
    handleBtn(row) {
      this.rowId = row.id
      if (row.remark) {
        this.remark = row.remark;
        this.dialogTitle = "修改备注";
      } else {
        this.remark = "";
        this.dialogTitle = "添加备注";
      }
      this.remarkVisible = true;
    },
    saveRemark(){
      const params = {
        id:this.rowId,
        remark:this.remark,
      }
      _api.saveRemark(params).then(res => {
        if(res.code === 1){
          this.$message.success("操作成功");
          this.handleCurrentChange()
          this.remarkVisible = false;
        }
      })
    },
    //弹窗确定
    handleSuccess() {
      if(this.remarkVisible){
        this.saveRemark()
      }
    },
    //弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    handleDialogClosed() {
      this.remarkVisible = false
      this.dialogVisible = false;
    },
    // 押金添加/查看点击
    handleShowDeposit(row,val,type) {
      this.voucherRowId = row.id
      this.opt = val
      if(type == 'add'){
        this.voucherVisible = true
      }else{
        this.voucherSeeTitle = val == '01'?'押金收取记录':'押金退还记录'
        this.voucherSeeVisible = true
        this.voucherChangeDialog()
      }
    },
    voucherClosed() {
      this.voucherVisible = false
      this.voucherLetterUrl = []
      this.voucherRemark = ""
    },
    // 上传图片后，将图片信息保存到对应参数中
		handleUploadGroupChange (list) {
      console.log(list);
			if (Array.isArray(list)) {
        this.voucherLetterUrl = list
			}
		},
    voucherSuccess() {
      if(!this.voucherLetterUrl.length){
        this.$message.error('请上传凭证')
        return
      }
      let params = {
        depositProofUrl: this.voucherLetterUrl,
        opt: this.opt,
        remark: this.voucherRemark,
        sendRecordId: this.voucherRowId
      }
      _api.saveSendDeposit(params).then(res => {
        if(res.code === 1){
          this.$message.success('添加成功')
          this.voucherVisible = false
          this.handleCurrentChange()
        }
      })
    },
    voucherSeeClose() {
      this.voucherSeeVisible = false
    },
    voucherChangeDialog(val) {
      if (val) {
        this.voucherPage.pageNum = val;
      } else {
        this.voucherPage.pageNum = 1;
      }
      let params = {
        opt: this.opt,
        sendRecordId: this.voucherRowId,
        pageNum: this.voucherPage.pageNum,
        pageSize: this.voucherPage.pageSize
      }
      this.voucherLoading = true
      _api.getSendDeposit(params).then(res => {
        if(res.code === 1){
          this.voucherData = res.data.records;
          this.voucherPage.pageNum = res.data.current;
          this.voucherPage.total = res.data.total;
        }
        this.voucherLoading = false
      })
    },
    seeVoucher(row) {
      this.showViewer = true;
      this.imgList = row.depositProofUrl;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .text-btn {
    margin-bottom: 0 !important;
    text-decoration: underline;
    padding: 0 !important;
  }
  .number-table {
    margin: 20px 0;
  }
  /deep/.remark .el-textarea__inner {
    height: 150px !important;
  }
  .distribute-color {
    color: #f83232;
  }
  // 备注说明
.Remarks {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; //控制行数
  overflow: hidden;
}
.mb-10{
  margin-bottom: 10px;
}
}
</style>
